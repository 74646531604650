<template>

  <b-card>

  <b-form>

    <!-- Nombre -->
    <b-form-group
        label="Nombre"
        label-for="nombre"
    >
      <b-form-input
          id="nombre"
          maxlength="100"
          autofocus
          trim
          placeholder="Nombre de la Aplicación"
          v-model="aplicacion.sysNombre"
      />
    </b-form-group>

    <!-- Descripción -->
    <b-form-group
        label="Descripción"
        label-for="descripcion"
    >
      <b-form-textarea
          id="descripcion"
          autofocus
          trim
          placeholder="Descripción de la Aplicación"
          v-model="aplicacion.sysDescripcion"
      />
    </b-form-group>
    <!-- Link -->
    <b-form-group
        label="Link"
        label-for="link"
    >
      <b-form-input
          id="link"
          maxlength="200"
          autofocus
          trim
          placeholder="URL de la Aplicación"
          v-model="aplicacion.sysLink"
      />
    </b-form-group>


  </b-form>

    <div class="d-flex align-items-center mt-2">
      <label>Aplicación activa</label>
      <b-form-checkbox v-model="aplicacion.isActive" switch class="ml-2"/>
    </div>

    <hr>

    <div class="d-flex mt-2">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          :disabled="isBusy || hasSomeNullEmptyItem"
          @click.prevent="handleSubmit"
      >
        <template v-if="isBusy">
          <b-spinner small/>
          Guardando
        </template>
        <template v-else>
          Guardar
        </template>
      </b-button>

      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          :disabled="isBusy || hasSomeNullEmptyItem"
          @click="handleDiscard"
      >
        Descartar
      </b-button>
    </div>



  </b-card>


</template>

<script>

import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BFormCheckbox,
    BFormTextarea
} from 'bootstrap-vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

export default {
  name: 'AplicacionEdit',
  props: {

    aplicacionProp: {
      type: Object,
      required: true,
      default: {}
    }
  },

  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,
    ValidationErrors,
    ToastificationContent
  },

  directives: {
    Ripple,
  },

  data() {

    return {
      errors: [],
      isBusy: false,
      showSidebar: false,

      aplicacion: {
        sysNombre: this.aplicacionProp.sysNombre,
        sysDescripcion: this.aplicacionProp.sysDescripcion,
        sysLink: this.aplicacionProp.sysLink,
        isActive: this.aplicacionProp.sysEstado == 1 ? true : false
      },
    }
  },

  computed: {

    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {
      const nullable = ( element ) => element === null || element === ''
      return Object.values( this.aplicacion ).some( nullable )
    },
  },

  methods:{

    ...mapActions( 'aplicacion-module', [ 'updateAplicacion', 'getSistemas' ] ),

    // procesar peticion de guardar
    async handleSubmit(){


      try {

        this.errors = []

        this.isBusy = true

        const params = {
          sysId: this.aplicacionProp.sysId,
          sysUuid: this.aplicacionProp.sysUuid,
          sysNombre: this.aplicacion.sysNombre,
          sysDescripcion: this.aplicacion.sysDescripcion,
          sysLink: this.aplicacion.sysLink,
          sysEstado: this.aplicacion.isActive ? 1 : 0
        }

        await this.updateAplicacion( params )

        this.$emit('successEditMode')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sistema',
            icon: 'CheckIcon',
            text: '¡Aplicación actualizada exitosamente!',
            variant: 'success'
          },
        })

        this.showSidebar = false

      } catch ( error ) {

        // errores de autenticacion
        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }


      } finally {
        this.isBusy = false
      }

    },

    // procesar peticion de descartar
    handleDiscard(){
      this.$emit('closeEditMode')
    },

    // reset los campos del formulario
    resetForm(){
      Object.keys(this.aplicacion).forEach( key =>  this.aplicacion[ key ] = null )
    }

  },

  created() {
    console.log("created edit", this.aplicacion )
  }

}

</script>
